import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function animaGlobal(){
    
    gsap.set('.wrapper-blobs', {opacity: 0})
    gsap.set('.banner-texto-wrapper >  *', {opacity: 0})
    const tl = gsap.timeline()
    tl.from('.items-topo > *', {opacity: 0, y: 10 , stagger: 0.2 })
    .from('.banner-main', {opacity: 0 , y: 20}, 0.4)
    .to('.wrapper-blobs', { opacity: 1, y: -30})
    .to('.banner-texto-wrapper >  *',{ opacity : 1 , y: -10, stagger: 0.1}, '<+=0.2')
    
}