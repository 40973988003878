import { gsap } from "gsap";
import { height } from "../utils/dom";

/*export default function animateFrom(elem, direction) {

	direction = direction | 1;
	let delay = 0

	let x = 0,
		y = 0,
		scale = 1,
		skewYY = 0,
		ease = "power3",
		opacity = 1

	let duration = 1
	const att = elem.getAttribute('data-anima')
	const valorTransicao = elem.getAttribute("data-value")


	//direção
	if (att === "left") {
		x = valorTransicao || -100;
		y = 0;
	} else if (att === "right") {
		x = valorTransicao || 100;
		y = 0;
	} else if (att === "down") {
		y = valorTransicao || height(elem);

	} else if (att === "up") {
		y = - (valorTransicao || height(elem));

	}
	else if (att === "scale") {
		scale = 0.6;

	}
	else if (att === "opacity") {
		opacity = 0;

	}

	if (elem.hasAttribute('skewy')) {
		skewYY = 5
	}
	//delay
	if (elem.dataset.delay) {
		delay = elem.dataset.delay.includes('.') ? `${elem.dataset.delay}` : `.${elem.dataset.delay}`
	}
	if (elem.dataset.duration) {
		duration = `${elem.dataset.duration}`
	}
	if (elem.dataset.ease) {
		ease = `${elem.dataset.ease}`
	}



	gsap.timeline()
		.fromTo(elem, {
			x: x,
			y: y,
			scale: scale,
			skewY: skewYY,
			autoAlpha: opacity

		}, {
			// ease: CustomEase.get("easeSuave"),
			ease: ease,
			duration: duration,
			x: 0,
			y: 0,
			autoAlpha: 1,
			delay: delay,
			scale: 1,
			overwrite: "auto",
			skewY: 0,

			onComplete: () => elem.classList.add("animou"),
			// onStart: () => elem.closest('section').classList.add("animate-enter")
		}).timeScale(1)

}*/

function animations(attr) {
  const animation = {
		"fade-up": { ypercent: 30, opacity: 0 },
		"fade-down": { ypercent: -30, opacity: 0 },
    // "fade-left": { xPercent: 30, opacity: 0 },
    //"fade-right": { xPercent: -30, opacity: 0 },
    // "fade-up-right": { yPercent: 30, xPercent: -30, opacity: 0 },
    // "fade-up-left": { yPercent: 30, xPercent: 30, opacity: 0 },
    // "fade-down-right": { yPercent: -30, xPercent: -30, opacity: 0 },
    // "fade-down-left": { yPercent: -30, xPercent: 30, opacity: 0 },
    // "fade-zoom-in": { scale: 1.2, opacity: 0 },
    // "fade-zoom-out": { scale: 0.8, opacity: 0 },
    // "slide-up": { y: -100, opacity: 0 },
    // "slide-down": { y: 100, opacity: 0 },
    // "zoom": { scale:0 },
    //"fade-up": { y: 100, opacity: 0 },
    //"fade-down": { y: -100, opacity: 0 },
    "fade-left": { x: 100, opacity: 0 },
    "fade-right": { x: -100, opacity: 0 },
    "fade-up-right": { y: 100, x: -100, opacity: 0 },
    "fade-up-left": { y: 100, x: 100, opacity: 0 },
    "fade-down-right": { y: -100, x: -100, opacity: 0 },
    "fade-down-left": { y: -100, x: 100, opacity: 0 },
    "fade-zoom-in": { scale: 1.2, opacity: 0 },
    "fade-zoom-out": { scale: 0.8, opacity: 0 },
    "slide-up": { y: -100, opacity: 0 },
    "slide-down": { y: 100, opacity: 0 },
    "zoom": { scale:0 },
  };
  return animation[attr] || { opacity: 0 };
}

/**
 * [data-from]  // objeto contendo o from da animacao
 * [data-to]    // objeto contendo o to da animacao
 * [data-anima] // nome da animacao predefinida na funcao animations
 * [data-timeline] // Se quisermos adicionar um conjunto de animacoes em uma timeline anexada ao window
 **/

export default function animateFrom(elem) {
  const dataFrom = elem.getAttribute("data-from")
    ? JSON.parse(elem.getAttribute("data-from"))
    : {};
  const dataTo = elem.getAttribute("data-to")
    ? JSON.parse(elem.getAttribute("data-to"))
    : {};
  const dataAnima = elem.getAttribute("data-anima");
  const dataTimeline = elem.getAttribute("data-timeline");

  const fromAnimation = animations(dataAnima);

  const from = {
    ...fromAnimation,
    ...dataFrom,
  };

  const to = {
    xPercent: 0,
    yPercent: 0,
    opacity: 1,
    overwrite: "auto",
    scale: 1,
    rotate: 0,
    duration: 0.3,
    ...dataTo,
    onComplete: () => elem.classList.add("animou"),
  };

  const timeline = gsap.fromTo(elem, from, to).timeScale(1);

  if (!dataTimeline) return;

  if (window[dataTimeline]) window[dataTimeline].add(timeline);
  else window[dataTimeline] = gsap.timeline().add(timeline);
}